var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.drawer)?_c('div',[_c('v-navigation-drawer',{staticStyle:{"z-index":"200"},attrs:{"fixed":"","width":"735","right":"","temporary":"","stateless":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"drawer-wrapper"},[_c('v-flex',{staticClass:"py-3 px-5 d-flex border-bottom drawer-header"},[_c('div',{staticClass:"font-level-3-bold"},[_vm._v("Send Mail")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","tile":""},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"blue darken-4",attrs:{"tile":"","depressed":"","color":"white--text","disabled":_vm.pageLoading || !_vm.formValid},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Send")])],1),_c('v-form',{ref:"meetingForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.update_or_create.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('div',{staticClass:"drawer-content pt-5 px-5"},[_c('v-row',[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"btx-label required",attrs:{"for":"email "}},[_vm._v("Recipients")])]),_c('v-col',{staticClass:"my-auto py-0",attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"d-flex align-items-end"},[_c('ComboboxInput',{staticClass:"autocomplete-multiple",class:{
										required: !_vm.recipients,
									},attrs:{"hide-details":"","items":_vm.attendies,"outlined":"","placeholder":"Recipients","dense":"","deletable-chips":"","small-chips":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"blue darken-4","multiple":"","rules":[_vm.vrules.required(_vm.recipients, 'Recipients')]},on:{"change":function($event){return _vm.validateEmail($event)}},model:{value:(_vm.recipients),callback:function ($$v) {_vm.recipients=$$v},expression:"recipients"}}),_c('v-btn',{staticStyle:{"height":"30px !important"},attrs:{"tile":"","depressed":"","color":"green white--text"},on:{"click":function($event){_vm.isCC = !_vm.isCC}}},[_vm._v(" CC ")])],1)]),(_vm.isCC)?[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"btx-label",attrs:{"for":"email "}},[_vm._v("CC")])]),_c('v-col',{staticClass:"my-auto py-0",attrs:{"cols":"12","md":"10"}},[_c('ComboboxInput',{staticClass:"autocomplete-multiple",attrs:{"hide-details":"","items":_vm.attendies,"outlined":"","placeholder":"CC","dense":"","small-chips":"","deletable-chips":"","color":"blue darken-4","multiple":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading},on:{"change":function($event){return _vm.validateCCEmail($event)}},model:{value:(_vm.ccRecipients),callback:function ($$v) {_vm.ccRecipients=$$v},expression:"ccRecipients"}})],1)]:_vm._e(),_c('v-col',{staticClass:"my-auto",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"btx-label required",attrs:{"for":"subject "}},[_vm._v("Subject")])]),_c('v-col',{staticClass:"my-auto py-0",attrs:{"cols":"12","md":"10"}},[_c('TextInput',{class:{
									required: !_vm.subject,
								},attrs:{"hide-details":"","id":"subject","placeholder":"Subject","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.subject, 'Subject')]},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1),_c('v-col',{staticClass:"mt-3 py-0",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"btx-label required",attrs:{"for":"message"}},[_vm._v("Message")])]),_c('v-col',{staticClass:"my-auto py-0 mt-2",attrs:{"cols":"12","md":"10"}},[_c('ckeditor',{class:{
									required: !_vm.editorData,
								},attrs:{"editor":_vm.editor,"config":_vm.editorConfig},model:{value:(_vm.editorData),callback:function ($$v) {_vm.editorData=$$v},expression:"editorData"}})],1),_c('v-col',{staticClass:"my-auto",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"btx-label",attrs:{"for":"subject "}},[_vm._v("Attachment")])]),_c('v-col',{staticClass:"my-auto py-0 mt-2",attrs:{"cols":"12","md":"10"}},[_vm._l((_vm.files),function(file,index){return [_c('div',{key:index,staticClass:"d-flex mb-2"},[_c('div',{staticClass:"w-40 pr-3",staticStyle:{"margin-top":"-12px","overflow":"hidden"}},[_c('v-file-input',{staticClass:"mt-3",attrs:{"id":`document-file-${index}`,"placeholder":"Select File","outlined":"","prepend-icon":"","prepend-inner-icon":"mdi-attachment","hide-details":""},on:{"change":function($event){return _vm.updateFile(index, $event)},"click:clear":function($event){return _vm.updateFile(index, $event)}},model:{value:(file.file),callback:function ($$v) {_vm.$set(file, "file", $$v)},expression:"file.file"}})],1),_c('div',{staticClass:"w-40 pr-3",staticStyle:{"margin-top":"-12px"}},[_c('TextInput',{attrs:{"id":`document-name-${index}`,"hide-details":"","placeholder":"File Name","suffix":file.suffix},model:{value:(file.name),callback:function ($$v) {_vm.$set(file, "name", $$v)},expression:"file.name"}})],1),_c('div',{staticClass:"w-10 pr-3",staticStyle:{"margin-top":"-12px"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"disabled":_vm.files.length < 2,"color":"red lighten-1 white--text","icon":""},on:{"click":function($event){return _vm.removeFile(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('div',{staticClass:"w-10 pr-3",staticStyle:{"margin-top":"-12px"}},[_c('v-btn',{staticClass:"mt-3 ml-2",attrs:{"color":"blue darken-4 white--text","tile":"","depressed":""},on:{"click":function($event){return _vm.addMore()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)])]})],2)],2)],1)])],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }